<template>
  <div class="controls" :class="dynamicClasses">
    <div class="controls__jump" @click="onJumpClick('last')">
      <div class="controls__jump__icon">
        <img src="/icons/icn-player-back-green.svg" alt="" />
      </div>
      <div>Back</div>
    </div>
    <div class="separator" v-if="!isPlaySeekerActive" />
    <!--  Switch Retro  -->
    <div class="controls__switch-retro" v-if="!isPlaySeekerActive">
      <div class="controls__switch-retro__text">
        Current
      </div>
      <div>
        <BSwitch v-model="isRetro" />
      </div>
      <div class="controls__switch-retro__text">
        Vintage
      </div>
    </div>
    <!--  Type Selector  -->
    <div v-if="isRetro && !isPlaySeekerActive" class="controls__type-retro" @click="onRetroClick">
      Choose <span>Player / Team</span>
    </div>
    <div v-else-if="!isPlaySeekerActive" class="controls__type-selector">
      <div :class="{ active: selectedType === 1 }" @click="onTypeClick(1)">Games</div>
      <div :class="{ active: selectedType === 2 }" @click="onTypeClick(2)">Players</div>
      <div :class="{ active: selectedType === 3 }" @click="onTypeClick(3)">Teams</div>
    </div>
    <div class="separator" v-if="!isPlaySeekerActive" />
    <div class="controls__jump next" @click="onJumpClick('next')">
      <div>Next</div>
      <div class="controls__jump__icon">
        <img src="/icons/icn-player-next-green.svg" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Controls',
  components: {
    BSwitch: async () => {
      const { BSwitch } = await import('buefy/dist/esm/switch');
      return BSwitch;
    },
  },
  props: {
    hasRetro: {
      type: Boolean,
      default: false,
    },
    containerWidth: {
      type: Number,
      default: window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth,
    },
    isPlaySeekerActive: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dynamicClasses: [],
      isRetro: false,
      selectedType: 1,
    };
  },
  watch: {
    containerWidth: {
      immediate: true,
      handler(newValue) {
        this.dynamicClasses = [];
        if (this.hasRetro) {
          this.dynamicClasses.push('has-retro');
        }
        if (newValue < 451) {
          this.dynamicClasses.push('width-450');
        } else if (newValue < 481) {
          this.dynamicClasses.push('width-480');
        } else if (newValue < 871) {
          this.dynamicClasses.push('width-870');
        }
      },
    },
    selectedType: {
      immediate: true,
      handler(newValue) {
        this.$emit('change-type', newValue);
      },
    },
    isRetro: {
      immediate: true,
      handler(newValue) {
        this.$emit('change-retro', newValue);
      },
    },
  },
  methods: {
    onJumpClick(type) {
      this.$emit('click-jump', type);
    },
    onRetroClick() {
      this.$emit('click-retro');
    },
    onTypeClick(type) {
      this.selectedType = type;
    },
  },
};
</script>

<style scoped lang="scss">
$blue: #132739;
$blue2: #253239;
$blueActive: #428ee6;
$green: #cbee6b;
@mixin icon-dimensions {
  height: 40px;
  width: 40px;
}
@mixin icon-dimensions-450 {
  height: 32px;
  width: 32px;
}

.controls {
  width: 100%;
  font-size: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.4em 0;

  &__jump {
    grid-area: jump-b;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $blue;
    color: white;
    font-size: 1.0625em;
    border-radius: 0.4705em;
    padding: 0.647em 0;
    font-family: Roboto-Medium, sans-serif;
    width: 100%;
    max-width: 10em;
    cursor: pointer;

    &.next {
      grid-area: jump-n;
    }

    & > div {
      margin: 0 0.25em;
    }

    &__icon {
      @include icon-dimensions;

      & > img {
        @include icon-dimensions;
      }
    }
  }

  &__switch-retro {
    grid-area: retro;
    width: 100%;
    height: 100%;
    font-size: 0.9375em;
    max-width: 12.6667em;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0.6em;
    border: solid 1px #bfc9d2;
    padding: 1.1em 0 1em;
    font-family: Roboto-Bold, sans-serif;
    color: $blue;
    margin-right: 0.2em;

    & > div {
      margin: 0 0.5em;
    }
  }

  &__type-retro {
    grid-area: type;
    width: 100%;
    max-width: 19.421em;
    color: white;
    font-family: Roboto-Regular, sans-serif;
    font-size: 1.3125em;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.05;
    letter-spacing: -0.42px;
    padding: 0.9em 0;
    margin-left: 0.2em;
    border-radius: 0.38em;
    background-color: $blueActive;
    cursor: pointer;

    & > span {
      font-family: Roboto-Bold, sans-serif;
    }
  }

  &__type-selector {
    grid-area: type;
    width: 100%;
    max-width: 25.225em;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    background-color: $blue2;
    color: white;
    font-family: Roboto-Regular, sans-serif;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    border-radius: 0.5em;
    padding: 0.2em 0.2em;
    margin-left: 0.2em;

    & > div {
      background-color: $blue2;
      font-size: 0.9375em;
      border-radius: 0.4em;
      padding: 1.25em 0;
      cursor: pointer;

      &.active {
        font-family: Roboto-Bold, sans-serif;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.2;
        letter-spacing: normal;
        background-color: $blueActive;
      }
    }
  }

  &.has-retro {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &.width-870 {
      padding: 0.5em 0.5em;
      display: grid;
      grid-template-columns: 30% 36% 30%;
      grid-template-areas:
        'jump-b retro jump-n'
        'type type type';
      column-gap: 0.4em;

      & > div.separator {
        display: none;
      }

      .controls {
        &__jump {
          width: 100%;
          max-width: 13.47em;
          padding: 0.2em 0 0.3em;

          &:last-child {
            justify-self: flex-end;
          }

          &__icon {
            @include icon-dimensions-450;

            & > img {
              @include icon-dimensions-450;
            }
          }
        }

        &__switch-retro {
          width: 100%;
          max-width: 15.46em;
          margin-right: 0;
          padding: 0;
          justify-self: center;
        }

        &__type-retro {
          font-size: 1.1875em;
          justify-self: center;
          margin-left: 0;
          margin-top: 0.83em;
          max-width: 13.361em;
          padding: 0.6em 0 0.5em;
        }

        &__type-selector {
          width: 100%;
          margin-left: 0;
          max-width: unset;
          margin-top: 1em;
          height: 2.667em;

          & > div {
            font-size: 0.9375em;
            border-radius: 0.4em;
            padding: 0.6em 0 0.6em;
          }
        }
      }
    }
    &.width-480 {
      padding: 0.5em 0.5em;
      display: grid;
      grid-template-columns: repeat(10, 1fr);
      grid-template-areas:
        'jump-b jump-b jump-b jump-b jump-b jump-n jump-n jump-n jump-n jump-n'
        'retro retro retro retro type type type type type type';
      column-gap: 0.4em;

      & > div.separator {
        display: none;
      }

      .controls {
        &__jump {
          width: 100%;
          max-width: 13.47em;
          padding: 0.2em 0 0.3em;

          &__icon {
            @include icon-dimensions-450;

            & > img {
              @include icon-dimensions-450;
            }
          }
        }

        &__switch-retro {
          width: 100%;
          height: 2.667em;
          max-width: unset;
          margin-right: 0;
          margin-top: 1em;
          padding: 0;
        }

        &__type-retro {
          width: 100%;
          font-size: 1.1875em;
          margin-left: 0;
          margin-top: 0.8em;
          max-width: unset;
          padding: 0.6em 0 0.5em;
        }

        &__type-selector {
          width: 100%;
          margin-left: 0;
          max-width: unset;
          margin-top: 1em;
          height: 2.55em;

          & > div {
            font-size: 0.9375em;
            border-radius: 0.4em;
            padding: 0.55em 0 0.5em;
          }
        }
      }
    }
    &.width-450 {
      padding: 0.5em 0.5em;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-areas:
        'jump-b jump-n'
        'retro retro'
        'type type';
      column-gap: 1em;

      & > div.separator {
        display: none;
      }

      .controls {
        &__jump {
          width: 100%;
          max-width: 13.47em;
          padding: 0.2em 0 0.3em;

          &__icon {
            @include icon-dimensions-450;

            & > img {
              @include icon-dimensions-450;
            }
          }
        }

        &__switch-retro {
          width: 100%;
          font-size: 0.9375em;
          max-width: unset;
          margin-right: 0;
          margin-top: 1.5em;
          padding: 0.2em 0 0;
          height: 2.2em;
        }

        &__type-retro {
          width: 100%;
          font-size: 1.1875em;
          margin-left: 0;
          margin-top: 0.6em;
          max-width: unset;
          padding: 0.48em 0 0.38em;
        }

        &__type-selector {
          width: 100%;
          font-size: 0.9375em;
          margin-left: 0;
          max-width: unset;
          margin-top: 0.7em;
          height: 2.4em;

          & > div {
            font-size: 0.9375em;
            border-radius: 0.4em;
            padding: 0.4em 0 0.5em;
          }
        }
      }
    }
  }
}

.separator {
  height: 1.5em;
  border-right: 1px dashed #506270;
  margin: 0 0.4em;
}
</style>

<style lang="scss">
$green-switch: #b8df4d;
.controls .controls__switch-retro {
  & .switch input[type='checkbox'] + .check {
    background: $green-switch;
  }

  & .switch input[type='checkbox']:checked + .check {
    background: $green-switch;
  }

  & span.check:before {
    background: #132739;
  }

  label.switch {
    margin-right: 0;

    & span.control-label {
      display: none;
    }
  }
}
</style>
